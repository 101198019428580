import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthProvider';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import { FirebaseAppContext } from '../../Context/FirebaseAppProvider';
import { getFirestore, collection, addDoc } from "firebase/firestore"; 
import { SnackbarContext } from '../../Context/SnackBarProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getRandomName } from '../../js/email';

export const SendEmailButton = (props) => {
    const { pattern } = props;
    const firebaseApp = useContext(FirebaseAppContext);
    const { auth } = useContext(AuthContext);
    const db = getFirestore(firebaseApp);
    const {setSnackbar} = useContext(SnackbarContext);
    const [loading, setLoading] = useState(false);
    
    const onClick = () => {
        uploadAndSendEmail();
    };

    const uploadAndSendEmail = async () => {
        setLoading(true);
        const fileName = `${getRandomName()}.pdf`;
        const metadata = {
            contentType: 'application/pdf',
          };
        const storage = getStorage();
        const fileRef = ref(storage, `mail/${fileName}`);

        const uploadTask = uploadBytesResumable(fileRef, pattern, metadata);

        uploadTask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            default:
                console.log('Upload state: ', snapshot.state);
                break;
            }
        }, 
        (error) => {
            setSnackbar({
                text: `Error sending email`,
                severity: "error",
            });
            setLoading(false);
            return;
        }, 
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async(downloadURL) => {
                console.log(downloadURL);
                await sendEmail(downloadURL);
            });
        }
        );
    }

    const sendEmail = async (downloadURL) => {
        const { email } = auth.currentUser;
        await addDoc(collection(db, `mail`), {
            to: email,
            message: {
              subject: "Here is your Fore Edge Book Art Pattern",
              html: `Attached is your Fore Edge Book Art Pattern
                    <br><br>
                    ForeEdgeBookArt.com`,
              attachments: [{
                filename: 'pattern.pdf',
                path: downloadURL
              }]
            },
            metadata: {
                downloadURL: downloadURL
            }
        }).then(() => {
            setSnackbar({
                text: `Pattern sent to ${email}`,
                severity: "success",
            });
            setLoading(false);
        }).catch(() => {
            setSnackbar({
                text: `Error sending email`,
                severity: "error",
            });
            setLoading(false);
        });
    }

    return (
        <Button 
            disabled={loading} 
            onClick={onClick} 
            variant="outlined"  
            size="small" 
            startIcon={loading ? <CircularProgress style={{height: "20px", width: "20px"}} /> : <EmailIcon />}>
                Email Pattern
        </Button>
    );
}