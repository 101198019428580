import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { TopBar } from './TopBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { updateMetaData, deletePattern } from '../js/email';
import posthog from 'posthog-js';
import { AuthContext } from '../Context/AuthProvider';

// TODO: Instead of building URL from params, get PDF from storage and use that to decide if metadata should be updated
// TODO: This can be cheaper as we'll only write when necessary if the website is opened multiple times
export const SinglePattern = () => {
    const { auth } = useContext(AuthContext);
    const [params, setParams] = useState({n:null, mt:null});
    const pdfNameParamName = 'n';
    const mediaTokenParamName = 'mt';
    const navigate = useNavigate();

    const getURL = () => {
        const {n, mt} = params;
        if (!n || !mt) {
            return '';
        }
        const url = `https://firebasestorage.googleapis.com/v0/b/lotus-book-image.appspot.com/o/singlePatterns%2F${n}.pdf?alt=media&token=${mt}`;
        return url;
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramPdfName = urlParams.get(pdfNameParamName);
        const paramMediaToken = urlParams.get(mediaTokenParamName);
        setParams({n:paramPdfName, mt:paramMediaToken});

        posthog.capture('Purchase', {
            uid: auth?.currentUser?.uid,
            component: 'single_pattern',
            event: 'mounted', 
            pdfName: paramPdfName,
            mediaToken: paramMediaToken,
        });

        if (!paramPdfName) {
            navigate("/");
        }

        // delete PDF from storage
        if (!paramMediaToken) {
            deletePattern(paramPdfName, ()=>{
                posthog.capture('Purchase', {
                    uid: auth?.currentUser?.uid,
                    component: 'single_pattern',
                    event: 'pattern_deleted', 
                    pdfName: paramPdfName,
                    mediaToken: paramMediaToken,
                });
                navigate("/");
            });
        }

        // set pdf as paid
        if (paramPdfName && paramMediaToken) {
            posthog.capture('Purchase', {
                uid: auth?.currentUser?.uid,
                component: 'single_pattern',
                event: 'pattern_set_paid', 
                pdfName: paramPdfName,
                mediaToken: paramMediaToken,
            });
            updateMetaData(paramPdfName, {'paid': 'true'});
        }
    }, [navigate, auth?.currentUser?.uid]);

    return (
        <>
          <TopBar />
          <Container style={{marginTop: "16px", display: "flex", flexDirection: "column", alignItems: "stretch", alignContent: 'center'}}>

            {params.mt && <>
                <Typography variant="h5" gutterBottom style={{textAlign: 'center'}}>
                    🎉 Here is your pattern! 🎉
                </Typography>
                <Typography variant="p" gutterBottom style={{textAlign: 'center'}}>
                    Download your pattern and start creating Fore Edge Book Art!
                </Typography>

                <iframe 
                    src={getURL()} 
                    title="Pattern PDF"
                    style={{
                        height: '80vh',
                        width: '100%'
                    }}/>
            </>}

          </Container>
        </>
    );
};