import * as React from 'react';
import { TopBar } from './TopBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Item = ({title, children}) => {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{fontSize: "18px"}} >
                {title}
            </AccordionSummary>
            <AccordionDetails style={{paddingLeft: "20px", paddingRight: "20px"}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export const Faq = () => {
    return (
        <>
          <TopBar />
          <Container style={{marginTop: "16px", display: "flex", flexDirection: "column", alignItems: "stretch", alignContent: 'center'}}>

            <Typography variant="h5" gutterBottom style={{textAlign: 'center'}}>
              Frequently Asked Questions
            </Typography>

            <Item title="What is Fore Edge Book Art?">
              <p>Fore Edge Book Art is a craft where an image is displayed on the paper edges of a book. </p>
              <p>With just a book and your desired image, you generate a 'pattern' that is specific to your image, and book. </p>
              <p>The pattern will contain your image converted into strips that are then cut out, folded in half, and glued to the edges of a book's pages. </p>
              <p>When the book is fanned out, the image is revealed. </p>
              <p>Fore Edge Book Art can refer to both the craft itself, and this program that is used to generate patterns. </p>
            </Item>

            <Item title="Can I use a phone/tablet to access Fore Edge Book Art?">
              <p>Yes! </p>
              <p>Fore Edge Book Art is a web app, so it can be accessed via any Internet browser.</p>
            </Item>

            <Item title="How do I create a pattern?">
              <i>To be written</i>
            </Item>

            <Item title="Where can I get books?">
              <i>To be written</i>
            </Item>

            <Item title="How do I disable my pop-up blocker?">
              <i>To be written</i>
            </Item>

            <Item title="How many patterns can I create with Fore Edge Book Art?">
              <i>To be written</i>
            </Item>

            <Item title="What payment method does Fore Edge Book Art support?">
              <i>To be written</i>
            </Item>

            <Item title="How do I print a pattern?">
              <i>To be written</i>
            </Item>

            <Item title="Why does the image look distorted on the strips?">
              <i>To be written</i>
            </Item>

            <Item title="How do I cut a pattern into strips?">
              <i>To be written</i>
            </Item>

            <Item title="The strips are too long/short for my book, what should I do?">
              <i>To be written</i>
            </Item>

            <Item title="How do I fold the strips?">
              <i>To be written</i>
            </Item>

            <Item title="How do I attach the strips to the edge of a page?">
              <i>To be written</i>
            </Item>

            <Item title="What is a Lenticular pattern?">
              <i>To be written</i>
            </Item>

          </Container>
        </>
    );
};