import React, { useContext, useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, onSnapshot, addDoc, doc } from "firebase/firestore"; 
import { FirebaseAppContext } from '../../Context/FirebaseAppProvider';
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';
import { PatternContext } from '../../Context/PatternProvider';
import { getFileNameAndMediaTokenFromURL, getSinglePatternURLFromFileNameAndMediaToken } from '../../js/url';
import { formatPrice } from '../../js/numbers';
import { uploadPattern } from '../../js/email';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import posthog from 'posthog-js';

const PRODUCT_ID_SINGLE = 'prod_R3xL0sZjfMnehW';
const PRODUCT_ID_SUBSCRIPTION = 'prod_LiDI3vQOWKMNxF';

const priceDetails = [
    {
        name: 'Monthly',
        description: 'Create unlimited patterns with our monthly subscription',
        features: ['Create unlimited patterns', 'Cancel anytime', 'Perfect for new users', 'Makes a great gift', 'Ink saving patterns', 'Lenticular patterns'],
        interval: 'month',
        intervalCopy: 'per month',
    },
    {
        name: 'Yearly',
        description: 'Save your money with our yearly subscription',
        features: ['Create unlimited patterns', 'Cancel anytime', 'Perfect for frequent users', 'Makes a great gift', 'Ink saving patterns', 'Lenticular patterns'],
        interval: 'year',
        intervalCopy: 'per year',
    },
    {
        name: 'Single Pattern',
        description: 'Just want this pattern? You can buy it individually',
        features: ['Download pattern instantly', 'Try before subscribing', 'Perfect for occasional users', 'Makes a great gift', 'Ink saving patterns', 'Lenticular patterns'],
        interval: null,
        intervalCopy: 'one time',
    }
];

const PriceCard = (props) => {
    const firebaseApp = useContext(FirebaseAppContext);
    const db = getFirestore(firebaseApp);
    const { auth } = useContext(AuthContext);
    const { GetPattern } = useContext(PatternContext);
    const { setSnackbar } = useContext(SnackbarContext);
    const { priceInfo, inProgress } = props;
    const { data, id } = priceInfo;
    const [ subscriptionInProgress, setSubscriptionInProgress ] = inProgress;
    const details = priceDetails.find((a)=>{return a.interval === data.interval});
    const [ loading, setLoading ] = useState(false);

    const handleClick = async () => {
        posthog.capture('Purchase', { 
            uid: auth.currentUser.uid,
            interval: data.interval || 'single',
            component: 'price_card',
            event: 'click', 
        });
        setSubscriptionInProgress(true);
        setLoading(true);
        setSnackbar({
            text: "Redirecting you to secure payment",
            severity: "success",
        });
        if (data.interval) {
            await purchaseSubscription();
        } else {
            await purchaseSingle();
        }
    };

    const purchaseSingle = async () => {
        // Generate full pattern
        const { blob } = await GetPattern();

        // Upload pattern pdf to firebase storage - add metadata that it is not paid for
        const metaData = {
            'paid': 'false',
            'uid': auth.currentUser.uid,
        };
        const downloadURL = await uploadPattern(blob, 'singlePatterns', metaData);
        const { n, mt } = getFileNameAndMediaTokenFromURL(downloadURL);
        const success_url = getSinglePatternURLFromFileNameAndMediaToken(n, mt);
        const cancel_url = getSinglePatternURLFromFileNameAndMediaToken(n);

        posthog.capture('Purchase', {
            uid: auth.currentUser.uid,
            component: 'price_card',
            event: 'pattern_uploaded', 
            interval: data.interval || 'single',
            details: downloadURL,
        });
        
        // Generate checkout session
        const docRef = await addDoc(collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`), {
            price: id,
            success_url: success_url,
            cancel_url: cancel_url,
            allow_promotion_codes: true,
            mode: "payment",
        });

        // Redirect to checkout session
        onSnapshot(doc(db, `customers/${auth.currentUser.uid}/checkout_sessions`, docRef.id), (doc) => {
            const { error, url } = doc.data();
            if (error) {
                alert(`An error occured: ${error.message}`);
                posthog.capture('Purchase', {
                    uid: auth.currentUser.uid,
                    component: 'price_card',
                    event: 'error', 
                    interval: data.interval || 'single',
                    message: error.message,
                    details: 'redirecting to checkout session',
                });
            }
            if (url) {
                window.location.assign(url);
            }
        });   
    };

    const purchaseSubscription = async () => {
        // Generate checkout session
        const docRef = await addDoc(collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`), {
            price: id,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
        });

        // Redirect to checkout session
        onSnapshot(doc(db, `customers/${auth.currentUser.uid}/checkout_sessions`, docRef.id), (doc) => {
            const { error, url } = doc.data();
            if (error) {
                alert(`An error occured: ${error.message}`);
                posthog.capture('Purchase', {
                    uid: auth.currentUser.uid,
                    component: 'price_card',
                    event: 'error', 
                    interval: data.interval || 'single',
                    message: error.message,
                    details: 'redirecting to checkout session',
                });
            }
            if (url) {
                posthog.capture('Purchase', {
                    uid: auth.currentUser.uid,
                    component: 'price_card',
                    event: 'redirect_to_checkout', 
                    interval: data.interval || 'single',
                    details: 'redirecting to checkout session',
                });
                window.location.assign(url);
            }
        });
    };

    return (
        <Grid 
            size={{ xs: 12, sm: details.interval === null ? 12 : 6, md: 4 }}
            key={details.name}
            style={{minWidth: '265px'}}
        >
            <Card sx={[{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 3,
                    border: 1,
                    borderColor: 'divider',
                    boxShadow: 3,
                }]}
                style={{backgroundColor: '#FDFDFD'}}
                >
                <CardContent>
                    <Box sx={[{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                    }]}>
                        <Typography component="h3" variant="h6" style={{fontWeight: 600}}>
                            {details.name}
                        </Typography>
                        {details.interval === null && <Chip icon={<StarIcon fontSize="small" />} label="New" color="success" variant='outlined' />}
                    </Box>
                    <Box sx={[{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: null,
                        gap: 1
                    },]}>
                        <Typography component="h3" variant="h4">
                            ${formatPrice(data.unit_amount)}
                        </Typography>
                        <Typography component="h3" variant="h6">
                            {details.intervalCopy}
                        </Typography>
                    </Box>
                    <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                    {details.features.map((feature) => (
                        <Box key={feature} sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                            <CheckCircleRoundedIcon
                                sx={[{
                                    width: 20,
                                    color: 'primary.main'
                                }]}/>
                            <Typography
                                variant="subtitle2"
                                component={'span'}
                                sx={[{
                                    color: null
                                }]}>
                            {feature}
                            </Typography>
                        </Box>
                    ))}
                </CardContent>
                <CardActions>
                    <Button
                        fullWidth
                        variant={'contained'}
                        color={'primary'}
                        disabled={subscriptionInProgress}
                        onClick={handleClick}
                        endIcon={loading && <CircularProgress color="inherit" size={20} />}>
                            Get started
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

const Purchase = () => {
    const firebaseApp = useContext(FirebaseAppContext);
    const db = getFirestore(firebaseApp);
    const [subscriptionPrices, setSubscriptionPrices] = useState(null);
    const [singlePrices, setSinglePrices] = useState(null);
    const [subscriptionInProgress, setSubscriptionInProgress] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const getPrices = async () => {
            const queryProducts = query(collection(db, 'products'), where('active', '==', true));
            const snapshotProducts = await getDocs(queryProducts);

            snapshotProducts.forEach(async (product) => {
                const queryPrices = query(collection(db, `products/${product.id}/prices`), where('active', '==', true));
                const pricesSnapshot = await getDocs(queryPrices);

                const prices = [];
                pricesSnapshot.forEach((price) => {
                    prices.push({id: price.id, data: price.data()});
                });

                if (product.id === PRODUCT_ID_SINGLE) {
                    setSinglePrices(prices);
                } else if (product.id === PRODUCT_ID_SUBSCRIPTION) {
                    setSubscriptionPrices(prices);
                } else {
                    console.log("Unknown product id: ", product.id);
                }
            });
        };
        getPrices().then(()=>{
            setLoading(false);
        });
    }, [db]);

    if (loading) {
        return <CircularProgress style={{margin: '20px'}} />;
    }

    return (
        <Grid
            container
            gap={3}
            sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', margin: '20px' }}
        >
            {subscriptionPrices && <>
                <PriceCard priceInfo={subscriptionPrices.find((a)=>{return a.data.interval === 'month'})} inProgress={[subscriptionInProgress, setSubscriptionInProgress]}/>
                <PriceCard priceInfo={subscriptionPrices.find((a)=>{return a.data.interval === 'year'})} inProgress={[subscriptionInProgress, setSubscriptionInProgress]}/>
            </>}
            {singlePrices && <>
                <PriceCard priceInfo={singlePrices[0]} inProgress={[subscriptionInProgress, setSubscriptionInProgress]}/>
            </>}
        </Grid>
    );
};

export {Purchase};