import PDFDocument from 'pdfkit/js/pdfkit.standalone';
import { BOOK_INFO_DEFAULTS, ANGLE_LINES } from '../js/constants';

function mmToPoints(mm) {
    //72 points per inch
    return mm * 720 / 254;
}

const BuildPattern = async (props) => {
    return new Promise(async (resolve, reject) => {
        let {angleLine, isInkSaver, imagePrimaryDataURL, imageSecondaryDataURL, height, lastOddPage, callback, subscribed = false} = props;

        //input Validation
        height = Number(height);
        lastOddPage = Number(lastOddPage);
        if (height > BOOK_INFO_DEFAULTS.MAX_HEIGHT || lastOddPage > BOOK_INFO_DEFAULTS.MAX_LAST_ODD_PAGE) {
            return null;
        }
        const pages = (lastOddPage + 1) / 2;

        const {Jimp, blobStream} = window;
        const previewImageWidth = 200;
        const previewImageHeight = 200;
        const leftMargin = 36;
        const rightMargin = 576;
        const topMargin = 27;
        const bottomMargin = 756;
        const docWidth = 540;
        const stripsPerPage = isInkSaver ? 12 : 10;
        const stripWidth = docWidth / stripsPerPage;
        const stripHeight = mmToPoints(height);
        const stripOptions = {width: stripWidth / (imageSecondaryDataURL ? 4 : 2), height: stripHeight};

        const doc = new PDFDocument();
        const stream = doc.pipe(blobStream());
        stream.on('finish', () => {
            const url = stream.toBlobURL('application/pdf');
            let blob = stream.toBlob('application/pdf');
            resolve({ url, blob });
        });

        //Convert base64 image to Jimp image
        let jimpPrimaryImage = await Jimp.read(imagePrimaryDataURL);
        let jimpSecondaryImage;
        if (imageSecondaryDataURL) {
            jimpSecondaryImage = await Jimp.read(imageSecondaryDataURL);
        }

        //Preview page
        if (imageSecondaryDataURL) {
            doc.image(await jimpPrimaryImage.getBase64Async(Jimp.AUTO), leftMargin + (docWidth/2 - previewImageWidth) - 10, 100, {width: 200, height: 200});
            doc.image(await jimpSecondaryImage.getBase64Async(Jimp.AUTO), leftMargin + (docWidth/2) + 10, 100, {width: 200, height: 200});
        } else {
            doc.image(await jimpPrimaryImage.getBase64Async(Jimp.AUTO), leftMargin + (docWidth/2 - previewImageWidth/2) - 50, 100, {width: 300, height: 300});
        }
        if (!subscribed) {
            doc
            .fontSize(11)
            .fillColor('black')
            .text("This is only a partial pattern", leftMargin, 420, {width: docWidth,align: 'center'});
            doc
            .fontSize(8)
            .fillColor('black')
            .text("Subscribe now to create unlimited patterns", leftMargin, 440, {width: docWidth,align: 'center'});
            doc
            .fontSize(8)
            .fillColor('black')
            .text("or purchase this pattern individually", leftMargin, 450, {width: docWidth,align: 'center'});
        }
        doc.addPage();

        //squish image horizontally
        let resizedPrimaryImage = await jimpPrimaryImage.resize(pages, jimpPrimaryImage.bitmap.height);
        let resizedSecondaryImage;
        if (imageSecondaryDataURL) {
            resizedSecondaryImage = await jimpSecondaryImage.resize(pages, jimpSecondaryImage.bitmap.height);
        }
        
        let stripNumber = 0;

        for (let i = 0; i < pages; i++) {

            const x = leftMargin + (stripNumber * stripWidth);
            const y = topMargin;
            const number = i + 1;
            const oddPageNumber = number * 2 - 1;

            //Primary Strip
            //Read image - for some reason this needs to be done each iteration
            let resizedPrimaryJimpImage = await Jimp.read(resizedPrimaryImage);
            let stripPrimary = await resizedPrimaryJimpImage
            .crop(i, 0, 1, jimpPrimaryImage.bitmap.height)
            .getBase64Async(Jimp.AUTO);

            //Secondary Strip
            let resizedSecondaryJimpImage;
            let stripSecondary;
            if (imageSecondaryDataURL) {
                resizedSecondaryJimpImage = await Jimp.read(resizedSecondaryImage);
                stripSecondary = await resizedSecondaryJimpImage
                .crop(i, 0, 1, jimpSecondaryImage.bitmap.height)
                .getBase64Async(Jimp.AUTO);
            }

            //Cut line at the beginning of a page
            if (stripNumber === 0) {
                //Cut line
                doc.lineCap('butt').lineWidth(1)
                .moveTo(x, topMargin)
                .lineTo(x, stripHeight + topMargin)
                .strokeColor('#404040')
                .stroke();
            }

            //Sideways strip number
            let stripNumberOffset = angleLine === ANGLE_LINES.NONE ? 15 : 25;
            doc.rotate(90,{ origin: [x,y] });
            doc
            .fontSize(8)
            .fillColor('black')
            .text("< " + oddPageNumber, x + stripNumberOffset, y - 8, {width: 40, align: 'left'});
            doc.rotate(-90,{ origin: [x,y] });

            //Watermark
            if (stripNumber % 4 === 0) {
                doc.rotate(90,{ origin: [x,y] });
                doc
                .fontSize(7)
                .fillColor('#C4C4C4')
                .text("ForeEdgeBookArt.com", x + (stripHeight / 2 - 50), y - 8, {width: 100, align: 'center'});
                doc.rotate(-90,{ origin: [x,y] });
            }

            //Top Fold line
            doc.lineCap('butt').lineWidth(2).undash()
            .moveTo(x + (stripWidth / 2), topMargin - 20)
            .lineTo(x + (stripWidth / 2), topMargin - 10)
            .strokeColor('black')
            .stroke();

            //Image strip
            doc.image(stripPrimary, x + (stripWidth / 4), y, stripOptions);
            if (imageSecondaryDataURL) {
                doc.image(stripSecondary, x + (stripWidth / 4) + stripOptions.width, y, stripOptions);
            }

            //45deg cut lines
            if ([ANGLE_LINES.TOPONLY, ANGLE_LINES.TOPANDBOTTOM].includes(angleLine)) {
                //45deg cut line at top left of strip
                doc.lineCap('butt').lineWidth(1).undash()
                .moveTo(x, topMargin + (stripWidth / 2))
                .lineTo(x + (stripWidth / 2) - 5, topMargin + 5)
                .strokeColor('#7a7a7a')
                .stroke();
                //45deg cut line at top right of strip
                doc.lineCap('butt').lineWidth(1).undash()
                .moveTo(x + stripWidth, topMargin + (stripWidth / 2))
                .lineTo(x + (stripWidth / 2) + 5, topMargin + 5)
                .strokeColor('#7a7a7a')
                .stroke();
            }
            if ([ANGLE_LINES.BOTTOMONLY, ANGLE_LINES.TOPANDBOTTOM].includes(angleLine)) {
                //45deg cut line at bottom left of strip
                doc.lineCap('butt').lineWidth(1).undash()
                .moveTo(x, stripHeight + topMargin - (stripWidth / 2))
                .lineTo(x + (stripWidth / 2) - 5, stripHeight + topMargin - 5)
                .strokeColor('#7a7a7a')
                .stroke();
                //45deg cut line at bottom right of strip
                doc.lineCap('butt').lineWidth(1).undash()
                .moveTo(x + stripWidth, stripHeight + topMargin - (stripWidth / 2))
                .lineTo(x + (stripWidth / 2) + 5, stripHeight + topMargin - 5)
                .strokeColor('#7a7a7a')
                .stroke();
            }

            //Bottom Fold line
            doc.lineCap('butt').lineWidth(2).undash()
            .moveTo(x + (stripWidth / 2), stripHeight + topMargin + 10)
            .lineTo(x + (stripWidth / 2), stripHeight + topMargin + 20)
            .strokeColor('black')
            .stroke();
            
            stripNumber++;
            
            //Cut line between image strips
            if (stripNumber < stripsPerPage && i + 1 !== pages) {
                //Cut line
                doc.lineCap('butt').lineWidth(1)
                .moveTo(x + stripWidth, topMargin)
                .lineTo(x + stripWidth, stripHeight + topMargin)
                .strokeColor('#404040')
                .stroke();
            }

            //Cut line at the end of a page and there are more strips to be added or this is the last strip
            if (stripNumber === stripsPerPage  && i + 1 !== pages || i + 1 === pages) {
                //Cut line
                doc.lineCap('butt').lineWidth(1)
                .moveTo(x + stripWidth, topMargin)
                .lineTo(x + stripWidth, stripHeight + topMargin)
                .strokeColor('#404040')
                .stroke();
            }

            //Add new page if subscribed, no more strips will fit, and there are still more image strips to add
            if (subscribed && stripNumber >= stripsPerPage && i + 1 !== pages) {
                stripNumber = 0;
                doc.addPage();
            }

            //If not subscribed, don't add any more strip pages
            if (!subscribed && i + 1 >= stripsPerPage) {
                break;
            }
        }
        doc.end();
    });
};

export default BuildPattern;