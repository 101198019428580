import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ManageSubscription } from './ManageSubscription';
import { PasswordReset } from './PasswordReset';
import { Logout } from './Logout';
import { Contact } from './Contact';
import { AuthContext } from '../../Context/AuthProvider';

const AccountDrawer = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user } = useContext(AuthContext);

    const HeaderItem = () => {
        return (
            <ListItem style={{padding: '30px', textAlign: 'center', maxHeight: '40px'}} key={"Fore Edge Book Art"} disablePadding>
                <ListItemIcon>
                    <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Lotus Book Art" style={{maxWidth: "50px", maxHeight: "50px"}} />
                </ListItemIcon>
                <ListItemText  primary={"Fore Edge Book Art"} primaryTypographyProps={{fontWeight: '500'}}/>
            </ListItem>
        );
    };

    const EmailItem = () => {
        return (
            <ListSubheader style={{textAlign: 'center', lineHeight: '30px'}}>{user?.email}</ListSubheader>
        );
    };

    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={()=>{setDrawerOpen(false)}}>
                <List subheader={<HeaderItem />}>
                    <EmailItem />
                    <Divider />
                    <ManageSubscription callBack={()=>{setDrawerOpen(false)}} />
                    <PasswordReset callBack={()=>{setDrawerOpen(false)}} />
                    <Contact />
                    <Logout />
                </List>
            </Drawer>

            <IconButton
                edge="end"
                color="inherit"
                aria-label="Menu"
                onClick={()=>{setDrawerOpen(true)}}>
                <AccountCircle />
            </IconButton>
        </>
    );
}

export default AccountDrawer;