import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Container from '@mui/material/Container';
import { SnackbarProvider } from './Context/SnackBarProvider';
import { AuthProvider } from './Context/AuthProvider';
import { FirebaseAppProvider } from './Context/FirebaseAppProvider';
import { TopBar } from './Components/TopBar';
import { SignedInCheck } from './Components/SignInUp/SignedInCheck';
import { Faq } from './Components/Faq';
import { SinglePattern } from './Components/SinglePattern';
import { PostHogProvider } from 'posthog-js/react'

const options = {
  api_host: 'https://us.i.posthog.com',
}

const App = () => {
  return (
    <PostHogProvider 
      apiKey={'phc_kCd1SCQU8hXHOBOaSnqsJqXs6s1q3cYD1hCBrPdVUun'}
      options={options}
    >
      <FirebaseAppProvider>
        <AuthProvider>
          <SnackbarProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="*" element={<Main />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/sp/*" element={<SinglePattern />} />
              </Routes>
            </BrowserRouter>
          </SnackbarProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </PostHogProvider>
  );
}

const Main = () => {
  return (
    <>
      <TopBar />
      <Container style={{marginTop: "16px"}}>
        <SignedInCheck />
      </Container>
    </>
  );
};

export default App;
