import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpIcon from '@mui/icons-material/Help';

export const Contact = () => {

    const handleClick = async () => {
        window.location.href = 'mailto:matt@foreedgebookart.com';
    };

    return (
        <ListItem key={"Contact"} disablePadding>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={"Contact"} />
            </ListItemButton>
        </ListItem>
    );
};