import React, {useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { isMobile } from 'react-device-detect';
import { Purchase } from './Subscribe';
import { SubscribedCheck } from '../SubscribedCheck';
import { SendEmailButton } from '../EmailPattern/SendEmailButton';
import { PatternContext } from '../../Context/PatternProvider';

export const PrintPattern = (props) => {
  const {onResetClick, onBackClick} = props;

  return (
    <>
      <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}}>
        <div style={{flexGrow: 1}}/>
        <SubscribedCheck subscribed={<PrintPatternButton isSubscribed={true} {...props} />} notSubscribed={<PrintPatternButton isSubscribed={false} {...props} />} />
        <div style={{flexGrow: 1}}/>
        <div style={{display: "flex", gap: "16px"}}>
          <Button variant="outlined" onClick={onBackClick}>
            {'Back'}
          </Button>
          <Button variant="outlined" onClick={onResetClick}>
            {'Reset'}
          </Button>
        </div>
      </div>
    </>
  );
};
  
const PrintPatternButton = (props) => {
  const { isSubscribed } = props;
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(null);
  const [document, setDocument] = useState(null);
  const { GetPattern, GetSamplePattern, imagePrimaryDataURLState, imageSecondaryDataURLState } = useContext(PatternContext);
  const [ imagePrimaryDataURL ] = imagePrimaryDataURLState;
  const [ imageSecondaryDataURL ] = imageSecondaryDataURLState;

  useEffect(()=>{
    const fetchPattern = async () => {
      const { url, blob } = isSubscribed ? await GetPattern() : await GetSamplePattern();
      setUrl(url);
      setDocument(blob);
      setLoading(false);
    };
    fetchPattern();
  }, [GetPattern, GetSamplePattern, isSubscribed]);

  const openPattern = (safeURL) => {
    if (isMobile) {
      var newWin = window.open(safeURL);
      if(!newWin || newWin.closed || typeof newWin.closed=='undefined') { 
          alert('Please disable your popup blocker and try again.');
      }
    } else {
      setShowModal(true);
    }
  };

  const PatternModal = () => {
    return (
      <>
        {showModal && 
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
          <ModalDialog layout="fullscreen" style={{backgroundColor: 'transparent'}}>
            <Sheet
              variant="soft"
              sx={{
                borderRadius: 'md',
                p: 3,
                boxShadow: 'lg',
              }}
            >
              <ModalClose variant="plain" sx={{ m: 1 }} />
              <div style={{display: 'flex', alignItems: 'center', gap: '5px', marginRight: '25px'}}>
                <img src={process.env.PUBLIC_URL + '/Logo.png'} alt="Lotus Book Art" style={{maxWidth: "50px", maxHeight: "50px"}} />
                <div style={{flexGrow: 1}}/>
                <Button href={url} variant="outlined"  size="small" download startIcon={<DownloadIcon />}>Download</Button>
                {isSubscribed && <SendEmailButton pattern={document} />}
              </div>
              <iframe 
                src={url} 
                title="Pattern Preview"
                style={{
                    height: '80vh',
                    width: '100%'
                }}/>
            </Sheet>
          </ModalDialog>
        </Modal>}
      </>
    );
  };

  const PatternPreview = () => {
    return (
      <div style={{display: 'flex'}}>
          <img
            src={imagePrimaryDataURL}
            alt=""
            style={{maxHeight: "100px"}}
          />
          <img
            src={imageSecondaryDataURL}
            alt=""
            style={{maxHeight: "100px"}}
          />
        </div>
    );
  };

  const onGetPatternClick = () => {
    openPattern(url);
  };

  if (isSubscribed) {
    return (
      <>
        <PatternPreview />
        <Button 
          variant="contained" 
          size="large" 
          color="success"
          disabled={loading}
          style={{margin: "10px"}}
          onClick={onGetPatternClick}
          endIcon={loading ? <CircularProgress style={{height: "20px", width: "20px"}} /> : <DownloadIcon />}>
            Open
        </Button>
        <PatternModal />
      </>
    );
  } else {
    return (
      <>
        <PatternPreview />
        <Purchase />
        <Tooltip title="Only part of your pattern will be generated">
          <Button
            variant="text" 
            size="medium" 
            disabled={loading} 
            style={{margin: "10px"}}
            onClick={onGetPatternClick}>
              No thanks
          </Button>
        </Tooltip>
        <PatternModal />
      </>
    );
  }
};