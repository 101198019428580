export const STRIPE_ROLES = {
    PAYING_USER: "payingUser",
}

export const INTERVALS = {
    MONTH: 'month',
    YEAR: 'year',
}

export const IMAGETYPE = {
    SINGLE: 'single',
    DOUBLE: 'double',
}

export const ERROR_CODES = {
    POPUP_BLOCKED: "POPUP_BLOCKED",
    DOWNLOAD_SUCCESS: "DOWNLOAD_SUCCESS"
}

export const ANGLE_LINES = {
    NONE: "None",
    TOPONLY: "Top Only",
    BOTTOMONLY: "Bottom Only",
    TOPANDBOTTOM: "Top and Bottom",
};

export const BOOK_INFO_DEFAULTS = {
    MAX_HEIGHTMM: 260,
    MAX_LAST_ODD_PAGE: 2000,
    MAX_HEIGHTINCH: 10.25,
}

export const MEASUREMENT_UNITS = [
    {
      value: 'cm',
      label: 'cm',
    },
    {
      value: 'inch',
      label: 'inches',
    }
];

export const FRACTIONS = [
    {
        value: '0',
        label: '-',
    },
    {
        value: '0.125',
        label: '\u215B', // ⅛
    },
    {
        value: '0.25',
        label: '\u00BC', // ¼
    },
    {
        value: '0.375',
        label: '\u215C', // ⅜
    },
    {
        value: '0.5',
        label: '\u00BD', // ½
    },
    {
        value: '0.625',
        label: '\u215D', // ⅝
    },
    {
        value: '0.75',
        label: '\u00BE', // ¾
    },
    {
        value: '0.875',
        label: '\u215E', // ⅞
    },
];

export const STEP = {
	BrowseImage: 0,
	BookDetails: 1,
	PrintPattern: 2,
}