import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { BOOK_INFO_DEFAULTS, MEASUREMENT_UNITS, FRACTIONS } from '../../js/constants';
import { PatternContext } from '../../Context/PatternProvider';

export const BookDetails = (props) => {
    const { onNextClick, onBackClick } = props;

    const { 
      lastOddPageState, 
      heightState,
      measurementUnitState 
    } = useContext(PatternContext);

    const [lastOddPage, setLastOddPage] = lastOddPageState;
    const [height, setHeight] = heightState;
    const [measurementUnit, setMeasurementUnit] = measurementUnitState;
  
    const getHeightCM = (heightMM) => {
      let heightCM = heightMM / 10;
      return String(parseFloat(heightCM.toFixed(1))).replace(/^0+/, '');
    }
    const getHeightInchPrimary = (heightMM) => {
      let heightInch = heightMM / 25.4;
      if (parseFloat(heightInch.toFixed(2) % 1) >= 0.97){
        return String(parseFloat(Math.ceil(heightInch)));
      }
      return String(parseFloat(Math.floor(heightInch)));
    }
    const getHeightInchFraction = (heightMM) => {
      const heightInch = heightMM / 25.4;
      const fractionDecimal = (heightInch % 1).toFixed(3);
  
      if (heightMM > BOOK_INFO_DEFAULTS.MAX_HEIGHTMM) {
        return FRACTIONS[3].value;
      }
  
      if (fractionDecimal >= FRACTIONS[0].value && fractionDecimal < parseFloat(FRACTIONS[1].value) - parseFloat(1/16)) {
        return FRACTIONS[0].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[1].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[2].value) - parseFloat(1/16)) {
        return FRACTIONS[1].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[2].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[3].value) - parseFloat(1/16)) {
        return FRACTIONS[2].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[3].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[4].value) - parseFloat(1/16)) {
        return FRACTIONS[3].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[4].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[5].value) - parseFloat(1/16)) {
        return FRACTIONS[4].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[5].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[6].value) - parseFloat(1/16)) {
        return FRACTIONS[5].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[6].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[7].value) - parseFloat(1/16)) {
        return FRACTIONS[6].value;
      } else if (fractionDecimal >= parseFloat(FRACTIONS[7].value) - parseFloat(1/16) && fractionDecimal < parseFloat(FRACTIONS[7].value) + parseFloat(1/16)) {
        return FRACTIONS[7].value;
      } else {
        return FRACTIONS[0].value;
      }
    }
  
    const onChangeMeasurementUnit = (e) => {
      setMeasurementUnit(e.target.value);
    };
  
    const Height = {
      value: height,
      valueCM: getHeightCM(height),
      valueInchPrimary: getHeightInchPrimary(height),
      valueInchFraction: getHeightInchFraction(height),
      onChangeCM: (e) => {
        const rounded = Math.round(e.target.value * 10) / 10
        const height = Number(rounded) * 10;
        setHeight(height);
      },
      onChangeInchPrimary: (e) => {
        const primary = Math.round(e.target.value);
        const fraction = getHeightInchFraction(height);
        const heightInch = primary + parseFloat(fraction);
        const heightMM = Math.round(heightInch * 25.4);
        setHeight(heightMM);
      },
      onChangeInchFraction: (e) => {
        const primary = Math.round(getHeightInchPrimary(height));
        const fraction = parseFloat(e.target.value);
        const heightInch = primary + fraction;
        const heightMM = Math.round(heightInch * 25.4);
        setHeight(heightMM);
      },
      isValidCM: () => {
          return height &&                                    //non-empty
          height >= 25 &&                                       //greater than 0
          height <= BOOK_INFO_DEFAULTS.MAX_HEIGHTMM &&        //less than 260
          height % 1 === 0 &&                                 //no decimals
          height.toString().length <= 5;                      //prevent many 0s
      },
      isValidInch: () => {
        const primary = Math.round(getHeightInchPrimary(height));
        const fraction = parseFloat(getHeightInchFraction(height));
        const sum = primary + fraction;
        return getHeightInchPrimary(height) &&                                    //non-empty
        getHeightInchPrimary(height) > 0 &&                                       //greater than 0
        sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH &&                               //less than 10.25
        getHeightInchPrimary(height) % 1 === 0 &&                                 //no decimals
        getHeightInchPrimary(height).toString().length <= 5;                      //prevent many 0s
      },
      isValidInchFraction: () => {
        const primary = Math.round(getHeightInchPrimary(height));
        const fraction = parseFloat(getHeightInchFraction(height));
        const sum = primary + fraction;
        return sum <= BOOK_INFO_DEFAULTS.MAX_HEIGHTINCH                           //less than 10.25
      },
    };
    const LastOddPage = {
      value: lastOddPage,
      onChange: (e) => {
        setLastOddPage(Number(e.target.value).toString());
      },
      isValid: () => {
          return lastOddPage &&                                     //non-empty
          lastOddPage % 2 &&                                        //odd
          lastOddPage > 0 &&                                        //greater than 0
          lastOddPage <= BOOK_INFO_DEFAULTS.MAX_LAST_ODD_PAGE &&    //less than 5000
          lastOddPage % 1 === 0 &&                                  //no decimals
          lastOddPage.toString().length <= 5;                       //prevent many 0s
      },
    };
  
    return (
      <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}}>
        
        <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
          <img src={process.env.PUBLIC_URL + '/Height.png'} alt="" style={{height: "150px", width: "200px"}}/>
  
          <div style={{display: 'flex', maxWidth: '167px'}}>
            {measurementUnit === 'cm' &&
              <TextField
                variant="standard" 
                label="Page height" 
                inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
                color="primary"
                type="number"
                step='1'
                value={Height.valueCM}
                onChange={Height.onChangeCM}
                error={!Height.isValidCM()}
                InputLabelProps={{sx: {"&.MuiInputLabel-root": { overflow: "visible" }}}}
                helperText={!Height.isValidCM() && "That value is invalid"}
              />}
  
            {measurementUnit === 'inch' &&
              <>
                <TextField
                  variant="standard" 
                  label="Page height"
                  inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
                  color="primary"
                  type="number"
                  step='1'
                  value={Height.valueInchPrimary}
                  onChange={Height.onChangeInchPrimary}
                  error={!Height.isValidInch()}
                  InputLabelProps={{sx: {"&.MuiInputLabel-root": { overflow: "visible" }}}}
                  helperText={!Height.isValidInch() && "That value is invalid"}
                />
                <TextField
                  select
                  variant="standard"
                  label=" " 
                  value={Height.valueInchFraction}
                  onChange={Height.onChangeInchFraction}
                  style={{minWidth: "35px"}}>
                  {FRACTIONS.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </>}
            <TextField
              select
              variant="standard"
              label=" " 
              value={measurementUnit}
              onChange={onChangeMeasurementUnit}
              style={{minWidth: "55px"}}>
              {MEASUREMENT_UNITS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {
                    <Typography
                      style={{fontSize: "0.75rem"}}
                    >{option.label}</Typography>
                  }
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
  
        <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
          <img src={process.env.PUBLIC_URL + '/Pages.png'} alt="" style={{height: "150px", width: "200px"}}/>
          <TextField 
              variant="standard" 
              label="Last odd page"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              color="primary"
              type="number"
              step='1'
              value={LastOddPage.value}
              onChange={LastOddPage.onChange}
              error={!LastOddPage.isValid()}
              helperText={!LastOddPage.isValid() && "That value is invalid"}/>
        </div>
  
        <div style={{flexGrow: 1}}/>
  
        <div style={{display: "flex", gap: "16px"}}>
          <Button variant="outlined" onClick={onBackClick}>
            {'Back'}
          </Button>
          <Button variant="contained" style={{boxShadow: 'none'}} disabled={!Height.isValidCM() || !LastOddPage.isValid()} onClick={onNextClick}>
            {'Next'}
          </Button>
        </div>
      </div>
    );
  };