import React, { useState } from 'react';
import Stepper from '../Stepper';
import Typography from '@mui/material/Typography';
import { STEP } from '../../js/constants';
import { BrowseImage } from './BrowseImage';
import { BookDetails } from './BookDetails';
import { PrintPattern } from './PrintPattern';
import { PatternInputProvider } from '../../Context/PatternProvider';

const Main = () => {
  const [activeStep, setActiveStep] = useState(STEP.BrowseImage);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const renderTitle = () => {
    let title;
    switch (activeStep) {
      case STEP.BrowseImage:
      default:
        title = "Let's start by uploading an image";
        break;
      case STEP.BookDetails:
        title = "Great! Now let's get some book info";
        break;
      case STEP.PrintPattern:
        title = "Your pattern is ready!";
        break;
    }
    return (
      <div style={{display: "flex", justifyContent: "center", padding: "20px"}}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      </div>
    );
  }

  const renderStep = () => {
    let step;
    switch (activeStep) {
      case STEP.BrowseImage:
      default:
        step = (<BrowseImage onNextClick={handleNext} />);
        break;
      case STEP.BookDetails:
        step = (<BookDetails onNextClick={handleNext} onBackClick={handleBack} />);
        break;
      case STEP.PrintPattern:
        step = (<PrintPattern onResetClick={handleReset} onBackClick={handleBack} />);
        break;
    }
    return (
      step
    );
  }

  return (
    <>
      <PatternInputProvider>
        <Stepper activeStep={activeStep} />
        {renderTitle()}
        {renderStep()}
      </PatternInputProvider>
    </>
  );
}

export default Main;