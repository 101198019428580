import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import GroupsIcon from '@mui/icons-material/Groups';
import Box from '@mui/material/Box';
import { SignUp } from './SignUp';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const LandingPage = (props) => {
    const {onLinkClick} = props;
    const benefits = [
        "Turn old books into art",
        "Put any image on any book",
        "Create unlimited easy to follow patterns",
        "Create great gifts for any occasion",
        "Patterns save on ink"];
    const [activeImageStep, setActiveImageStep] = useState(0);

    const images = {
        originals: [
            {
                label: 'Sun flower image',
                imgPath:
                process.env.PUBLIC_URL + '/Image1.jpg',
            },
            {
                label: 'Cat',
                imgPath:
                process.env.PUBLIC_URL + '/Image2.jpg',
            }
        ],
        books: [
            {
                label: 'Sun flower book',
                imgPath:
                process.env.PUBLIC_URL + '/Book1.jpg',
            },
            {
                label: 'Cat',
                imgPath:
                process.env.PUBLIC_URL + '/Book2.jpg',
            }
        ]
    };
    const handleImageStepChange = (step) => {
        setActiveImageStep(step);
    };

    return (
        <div style={{borderRadius: "25px", backgroundColor: "#FEFEFF", padding: "16px", display: "flex", alignItems: "center", flexDirection: "column", gap: "10px"}}>

            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "nowrap", maxHeight: 300}}>
                <AutoPlaySwipeableViews
                    axis="x"
                    index={activeImageStep}
                    onChangeIndex={handleImageStepChange}
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {images.originals.map((step, index) => (
                        <div key={step.label} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            {Math.abs(activeImageStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 300,
                                    diplay: 'block',
                                    overflow: 'hidden'}}
                                src={step.imgPath}
                                alt={step.label}
                            />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <AutoPlaySwipeableViews
                    axis="x"
                    index={activeImageStep}
                    onChangeIndex={handleImageStepChange}
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {images.books.map((step, index) => (
                        <div key={step.label} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            {Math.abs(activeImageStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 300,
                                    diplay: 'block',
                                    overflow: 'hidden'}}
                                src={step.imgPath}
                                alt={step.label}
                            />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </div>
            
            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap-reverse"}}>

                <SignUp onLinkClick={onLinkClick}/>

                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                        {benefits.map((item)=>{
                            return (
                                <List dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon color="success" />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                </List>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={()=>{window.location.href="https://www.facebook.com/ForeEdgeBookArt/"}}>
                <FacebookIcon fontSize="large" sx={{ color: "#4267B2" }} />
                <Typography style={{marginLeft: "10px"}}>Like us on Facebook</Typography>
            </div>
            
            <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={()=>{window.location.href="https://www.facebook.com/groups/385065887790972/"}}>
                <GroupsIcon fontSize="large" sx={{ color: "#4267B2" }} />
                <Typography style={{marginLeft: "10px"}}>Join the community</Typography>
            </div>
            
        </div>
    );
};