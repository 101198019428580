import React, { createContext, useState } from "react";
import { ANGLE_LINES, IMAGETYPE, MEASUREMENT_UNITS } from '../js/constants';
import BuildPattern from '../js/PatternBuilder';

export const PatternContext = createContext();

export const PatternInputProvider = (props) => {
    const [imagePrimaryDataURL, setImagePrimaryDataURL] = useState(null);
    const [imageSecondaryDataURL, setImageSecondaryDataURL] = useState(null);
    const [imageType, setImageType] = useState(IMAGETYPE.SINGLE);
    const [lastOddPage, setLastOddPage] = useState(249);
    const [height, setHeight] = useState(220);
    const [angleLine, setAngleLine] = useState(ANGLE_LINES.NONE);
    const [isInkSaver, setIsInkSaver] = useState(false);
    const [measurementUnit, setMeasurementUnit] = useState(MEASUREMENT_UNITS[0].value);

    const GetPattern = async() => {
        let imageSecondaryDataURLInput = imageType === IMAGETYPE.DOUBLE ? imageSecondaryDataURL : null;
        return await BuildPattern({angleLine, isInkSaver, imagePrimaryDataURL, imageSecondaryDataURL: imageSecondaryDataURLInput, height, lastOddPage, callback: ()=>{}, subscribed: true}); 
    };

    const GetSamplePattern = async() => {
        let imageSecondaryDataURLInput = imageType === IMAGETYPE.DOUBLE ? imageSecondaryDataURL : null;
        return await BuildPattern({angleLine, isInkSaver, imagePrimaryDataURL, imageSecondaryDataURL: imageSecondaryDataURLInput, height, lastOddPage, callback: ()=>{}, subscribed: false}); 
    };

    const patternContext = { 
        imagePrimaryDataURLState: [imagePrimaryDataURL, setImagePrimaryDataURL], 
        imageSecondaryDataURLState: [imageSecondaryDataURL, setImageSecondaryDataURL],
        imageTypeState: [imageType, setImageType],
        lastOddPageState: [lastOddPage, setLastOddPage],
        heightState: [height, setHeight],
        angleLineState: [angleLine, setAngleLine],
        isInkSaverState: [isInkSaver, setIsInkSaver],
        measurementUnitState: [measurementUnit, setMeasurementUnit],
        GetPattern,
        GetSamplePattern
    }

    return (
        <PatternContext.Provider value={patternContext}>
            {props.children}
        </PatternContext.Provider>
    );
};