import React, { useState, useContext } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import { signInWithEmailAndPassword } from "firebase/auth";
import ForgotPassword from "../ForgotPassword";
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';

export const SignIn = (props) => {
    const {onLinkClick} = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {setSnackbar} = useContext(SnackbarContext);
    const { auth } = useContext(AuthContext);

    const onSignInClick = () => {
        signInWithEmailAndPassword(auth, email.trim(), password)
        .then((userCredential) => {
            const user = userCredential.user;
            setSnackbar({
                text: "Welcome back!",
                severity: "success",
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message.replace('Firebase: ', '');
            setSnackbar({
                text: errorMessage,
                severity: "error",
            });
        });
    };

    return (
        <div style={{marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", justifyContent:"space-evenly", alignItems: "center", minHeight: "300px", width:"250px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}}>
            <Typography variant="h4" gutterBottom component="div">
                Sign in
            </Typography>
            <TextField
                required
                label="Email"
                variant="standard"
                value={email}
                onChange={(e)=>{setEmail(e.target.value)}}/>
            <TextField
                required
                label="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}/>
            <ForgotPassword />
            <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={onSignInClick}>
                Sign In
            </Button>
            <Link onClick={onLinkClick}>Don't have an account yet?</Link>
        </div>
    );
};