import React, { useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from '../../js/account';
import { AuthContext } from '../../Context/AuthProvider';

export const Logout = () => {
    const { auth } = useContext(AuthContext);

    const handleClick = async () => {
        logOut(auth);
        window.location.href = '/';
    };

    return (
        <ListItem key={"Logout"} disablePadding>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
            </ListItemButton>
        </ListItem>
    );
};