import React, { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../Context/AuthProvider';
import Main from '../Main/Index';
import SignInUp from './SignInUp';

export const SignedInCheck = () => {
    const { user } = useContext(AuthContext);
  
    if (user === undefined) {
      return (<div style={{display: "flex", justifyContent: "center"}}>
        <CircularProgress />
      </div>);
    }
    if (user) {
      return <Main />;
    }
    return <SignInUp />;
  }