import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ImageBrowseButton from '../ImageBrowseButton';
import TuneIcon from '@mui/icons-material/Tune';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { ANGLE_LINES, IMAGETYPE } from '../../js/constants';
import { PatternContext } from '../../Context/PatternProvider';

export const BrowseImage = (props) => {
    const { onNextClick } = props;

    const { 
      imagePrimaryDataURLState, 
      imageSecondaryDataURLState,
      imageTypeState,
      angleLineState,
      isInkSaverState 
    } = useContext(PatternContext);

    const [imagePrimaryDataURL, setImagePrimaryDataURL] = imagePrimaryDataURLState;
    const [imageSecondaryDataURL, setImageSecondaryDataURL] = imageSecondaryDataURLState;
    const [imageType, setImageType] = imageTypeState;
    const [angleLine, setAngleLine] = angleLineState;
    const [isInkSaver, setIsInkSaver] = isInkSaverState;
    
    const [showAdvancedModal, setShowAdvancedModal] = useState(false);
    const onShowAdvancedClick = () =>{
      setShowAdvancedModal(true);
    }
    const AdvancedSettingsModal = () => {
      // Ink saver
      const [anchorInfoInkSaver, setAnchorInfoInkSaver] = useState(null);
      const openPopoverInkSaver = Boolean(anchorInfoInkSaver);
      const inkSaverPopupId = openPopoverInkSaver ? 'ink-saver-popover' : undefined;
      const handleInfoInkSaverClick = (event) => {
        setAnchorInfoInkSaver(event.currentTarget);
      };
      const handleInfoInkSaverClose = () => {
        setAnchorInfoInkSaver(null);
      };
      const handleInkSaverChange = (event) => {
        setIsInkSaver(event.target.checked);
      };
  
      //Angle line
      const [anchorInfoAngleLine, setAnchorInfoAngleLine] = useState(null);
      const openPopoverAngleLine = Boolean(anchorInfoAngleLine);
      const angleLinePopupId = openPopoverAngleLine ? 'angle-line-popover' : undefined;
      const handleInfoAngleLineClick = (event) => {
        setAnchorInfoAngleLine(event.currentTarget);
      };
      const handleInfoAngleLineClose = () => {
        setAnchorInfoAngleLine(null);
      };
      const handleAngleLineChange = (event) => {
        setAngleLine(event.target.value);
      };
  
      return (
        <>
          {showAdvancedModal && 
          <Modal
            open={showAdvancedModal}
            onClose={() => setShowAdvancedModal(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}
          >
            <ModalDialog layout="center" sx={{backgroundColor: 'transparent', border: 'none', boxShadow: 'none', width: '-webkit-fill-available'}} >
              <Sheet
                variant="soft"
                sx={{
                  borderRadius: 'md',
                  p: 3,
                  boxShadow: 'lg',
                }}
              >
                <ModalClose variant="plain" sx={{ m: 1 }} />
                <Typography gutterBottom>
                  Advanced Settings
                </Typography>
  
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                  
                  <FormControl>
                    <FormLabel>Pattern type</FormLabel>
                    <RadioGroup
                      value={imageType}
                      onChange={handleImageTypeChange}
                    >
                      <FormControlLabel value={IMAGETYPE.SINGLE} control={<Radio />} label="Single Image" />
                      <FormControlLabel value={IMAGETYPE.DOUBLE} control={<Radio />} label="Lenticular" />
                    </RadioGroup>
                  </FormControl>
  
                  <div style={{flexGrow: 1}}/>
  
                  <FormControl>
                    <div>
                      <FormControlLabel control={<Switch 
                        checked={isInkSaver}
                        onChange={handleInkSaverChange}/>} label="Ink Saver" />
                      <InfoOutlinedIcon onClick={handleInfoInkSaverClick} fontSize="xsmall" />
                    </div>
                    
                    <Popover
                      id={inkSaverPopupId}
                      open={openPopoverInkSaver}
                      anchorEl={anchorInfoInkSaver}
                      onClose={handleInfoInkSaverClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Typography sx={{ p: 1 }}>Use thinner strips to save on ink</Typography>
                    </Popover>
                  </FormControl>
  
                  <div style={{flexGrow: 1}}/>
  
                  <FormControl>
                    <div>
                      <FormControlLabel size="small" control={
                        <>
                          <Select
                            variant="standard"
                            value={angleLine}
                            label="Angle Line"
                            onChange={handleAngleLineChange}
                          >
                            <MenuItem value={ANGLE_LINES.NONE}>{ANGLE_LINES.NONE}</MenuItem>
                            <MenuItem value={ANGLE_LINES.TOPONLY}>{ANGLE_LINES.TOPONLY}</MenuItem>
                            <MenuItem value={ANGLE_LINES.BOTTOMONLY}>{ANGLE_LINES.BOTTOMONLY}</MenuItem>
                            <MenuItem value={ANGLE_LINES.TOPANDBOTTOM}>{ANGLE_LINES.TOPANDBOTTOM}</MenuItem>
                          </Select>
                        </>
                      } label=" 45° cut lines" />
                      <InfoOutlinedIcon onClick={handleInfoAngleLineClick} fontSize="xsmall" />
                    </div>
                    
                    <Popover
                      id={angleLinePopupId}
                      open={openPopoverAngleLine}
                      anchorEl={anchorInfoAngleLine}
                      onClose={handleInfoAngleLineClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Typography sx={{ p: 1 }}>Show lines to guide angle cuts at the top and bottom of each strip</Typography>
                    </Popover>
                  </FormControl>
                </div>
              </Sheet>
            </ModalDialog>
          </Modal>}
        </>
      );
    };
  
    const handleImageTypeChange = (event) => {
      setImageType((event.target).value);
    };
  
    const isNextButtonBisabled = () => {
      if (imageType === IMAGETYPE.SINGLE) {
        return imagePrimaryDataURL ? false : true;
      }
      //Double
      return imagePrimaryDataURL && imageSecondaryDataURL ? false : true;
    }
  
    return (
      <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", minHeight: "300px", borderRadius: "15px", backgroundColor: "#FEFEFF", padding: "16px"}} >
        
        <Button 
          variant="text" 
          size="small" 
          color="inherit"
          onClick={onShowAdvancedClick}
          endIcon={<TuneIcon />}
          style={{marginBottom: "20px"}}>
            Advanced Settings
        </Button>
        <AdvancedSettingsModal />
  
        <div style={{flexGrow: 1}}/>
  
        <div style={{display: "flex"}}>
  
          {/* Primary Image */}
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "300px"}}>
            <ImageBrowseButton id={'1'} imageDataURL={imagePrimaryDataURL} callback={setImagePrimaryDataURL}/>
          </div>
  
          {/* Secondary Image */}
          {imageType === IMAGETYPE.DOUBLE && 
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "300px"}}>
            <ImageBrowseButton id={'2'} imageDataURL={imageSecondaryDataURL} callback={setImageSecondaryDataURL}/>
          </div>}
  
        </div>
  
        <div style={{flexGrow: 1}}/>
  
        <Button variant="contained" style={{boxShadow: 'none'}} disabled={isNextButtonBisabled()} onClick={onNextClick}>
          {'Next'}
        </Button>
      </div>
    );
};