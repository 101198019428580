import React, { useState, useContext } from 'react';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { sendPasswordResetEmail } from "firebase/auth";
import { SnackbarContext } from '../Context/SnackBarProvider';
import { AuthContext } from '../Context/AuthProvider';

const ForgotPassword = () => {
    const { setSnackbar } = useContext(SnackbarContext);
    const { auth } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const resetPassword = () => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            setSnackbar({
                text: "Password reset email sent",
                severity: "success",
            });
        })
        .catch((error) => {
            setSnackbar({
                text: "Unable to send reset email",
                severity: "error",
            });
        });
    }

    return (
        <>
            <Link href="#" onClick={handleOpen} underline="hover" variant="caption">Forgot password?</Link>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle id="alert-dialog-title">
                    Password reset
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{paddingBottom: "20px"}}>
                        Enter your email and we'll send you a link to reset your password
                    </DialogContentText>
                    <TextField
                        required
                        label="Email"
                        variant="standard"
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={()=>{resetPassword()}}>
                        Reset password
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ForgotPassword;