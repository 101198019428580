import React, { useState, useContext } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import { SnackbarContext } from '../../Context/SnackBarProvider';
import { AuthContext } from '../../Context/AuthProvider';

export const SignUp = (props) => {
    const {onLinkClick} = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {setSnackbar} = useContext(SnackbarContext);
    const { auth } = useContext(AuthContext);

    const onSignUpClick = () => {
        createUserWithEmailAndPassword(auth, email.trim(), password)
        .then((userCredential) => {
            const user = userCredential.user;
            setSnackbar({
                text: "Welcome!",
                severity: "success",
            });
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message.replace('Firebase: ', '');
            setSnackbar({
                text: errorMessage,
                severity: "error",
            });
        });
    };

    return (
        <div style={{display: "flex", flexDirection: "column", minHeight: "300px", justifyContent:"center", alignItems: "center"}}>
            <Typography variant="h4" gutterBottom component="div">
                Create Account
            </Typography>
            <TextField
                style={{marginBottom: "15px", width: "200px"}}
                required
                label="Email"
                variant="standard"
                value={email}
                onChange={(e)=>{setEmail(e.target.value)}}/>
            <TextField
                style={{marginBottom: "15px", width: "200px"}}
                required
                label="Password"
                variant="standard"
                type="password"
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}/>
            <Button
                style={{marginBottom: "15px"}}
                variant="contained"
                size="large"
                color="success"
                onClick={onSignUpClick}>
                Sign Up
            </Button>
            <Link onClick={onLinkClick}>Already have an account?</Link>
        </div>
    );
};