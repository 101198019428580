import React, { useState } from 'react';
import { SignIn } from './SignIn';
import { LandingPage } from './LandingPage';

const SignInUp = () => {
    const [page, setPage] = useState('SignUp');
    const switchPage = () => {
        switch (page) {
            case 'SignUp':
            default:
                setPage('SignIn');
                break;
            case 'SignIn':
                setPage('SignUp');
                break;
        }
    };
    const getPage = () => {
        switch (page) {
            case 'SignUp':
            default:
                return <LandingPage onLinkClick={()=>{switchPage()}}/>
            case 'SignIn':
                return <SignIn onLinkClick={()=>{switchPage()}}/>
        }
    };

    return getPage();
};

export default SignInUp;